.appbar-div {
  flex-grow: 1;
}

.title {
  flex-grow: 1;
}

.appbar {
  position: static;
}

.background-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  filter: blur(5px);
  position: fixed;
  left: 8;
  right: 8;
}

.page-content {
  height: 100vh;
}
