$background: #fffffe;
$background-secondary: #d8eefe;
$headline: #094067;
$paragraph: #5f6c7b;
$button: #3da9fc;
$button-text: #fffffe;
$action: #ef4565;
$margin-horizontal: 10%;
$margin-vertical: 2%;
$navbar-height: 100px;
